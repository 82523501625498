<template>
  <div>Wir melden Sie ab...</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    this.logout().then(() => {
      this.$toasted.success('Sie wurden erfolgreich abgemeldet')
      this.$router.replace('/login')
    })
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>
